@font-face {
  font-family: 'Roobert';
  src: url('./Roobert-Regular.woff2') format('woff2'), url('./Roobert-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert';
  src: url('./Roobert-SemiBold.woff2') format('woff2'), url('./Roobert-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
