.footer {
  padding: 50px 0;
  text-align: center;
}

.footer svg {
  margin: 0 auto;
}

.footer p {
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 10px;
}
