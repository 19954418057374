* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: 'Roobert', sans-serif;
  font-weight: 400;
  background-color: var(--bg);
  color: var(--text);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'ss01', 'ss02', 'ss03', 'ss04', 'ss05', 'ss06', 'liga' 0, 'dlig' 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: 600;
  font-size: 16px;
}

input,
button {
  -ms-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
}

img,
svg,
video {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
}
