.grid {
  display: flex;
  flex-wrap: wrap;
  background: var(--cards);
  color: var(--cardText);
}

.grid-item {
  width: 100%;
  border-bottom: 1px solid var(--cardBorder);
  padding: 20px 16px;
  font-size: 14px;
}

.grid-item:last-of-type {
  border-bottom: none;
}

.grid-item.grid-item--1\/2 {
  width: 50%;
}

.grid-item.grid-item--1\/2 + .grid-item.grid-item--1\/2 {
  border-left: 1px solid var(--cardBorder);
}

.grid-item.grid-item--social a {
  display: block;
}

.grid-item h1 {
  font-size: 24px;
}

.grid-item h2 {
  font-size: 14px;
  margin-bottom: 20px;
}

.grid-item-meta {
  margin: 16px 0;
}

.grid-item a {
  color: var(--cardText);
  font-weight: 600;
}

.grid-item p a {
  font-weight: 400;
  text-decoration: underline;
}

.grid-item-social-icon {
  margin-bottom: 8px;
}

.grid-item-social-text {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.grid-item .claim-item-flex {
  display: flex;
  align-items: center;
  gap: 16px;
}

.grid-item .claim-item-flex-image {
  width: 70px;
}

.grid-item .claim-item-flex-image img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.grid-item .claim-item-flex-text {
  flex: 1;
}

.grid-item .claim-item-flex-text h3 {
  font-size: 14px;
  margin-bottom: 4px;
}

.grid-item .claim-item-flex-text p {
  color: var(--cardLightText);
}

.grid-item .claim-item-button {
  background: var(--bg);
  color: var(--text);
  font-weight: 600;
  border-radius: 80px;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 16px;
  font-size: 16px;
  line-height: 32px;
}

.grid-item-description {
  & > * + * {
    margin-top: 20px;
  }
}
